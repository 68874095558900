import React, { Component } from 'react';
import { Telephone, Mailbox } from 'react-bootstrap-icons';

// Import Images
import bg1 from '../../images/main-banner/bg1.jpg';
import doctorImg from '../../images/main-banner/doctor.png';
import ptImg1 from '../../images/shap/trangle-orange.png';
import ptImg2 from '../../images/shap/square-blue.png';
import ptImg3 from '../../images/shap/chicle-blue-2.png';
import ptImg4 from '../../images/shap/plus-orange.png';
import ptImg5 from '../../images/shap/wave-orange.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<div className="main-banner" style={{backgroundImage: "url("+bg1+")"}}>
					<div className="container inner-content">
						<div className="row align-items-start" style={{marginTop: "20%"}}>
							<div className="col-lg-7 col-md-6 col-sm-7" style={{marginTop: "15%"}}>
								<h6 className="title-ext text-primary" >Soluções em Home Care</h6>
								<h1>O cuidado que você precisa no lugar que você deseja</h1>
							</div>
							<div className="col-lg-7 col-md-6 col-sm-7" >
								<Telephone size={40}/>
								<h6  className="title-ext text-primary">(85) 2010-0799 / (85) 98105-0326</h6>
								<br></br>
								<Mailbox size={40}/>
								<h6 className="title-ext text-primary">diretoria@newhealthhomecare.com.br</h6>
							</div>
							<div className="col-lg-5 col-md-6 col-sm-5">
								<div className="banner-img">
									<img src={doctorImg} alt=""/>
								</div>
							</div>
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg1} alt=""/>
					<img className="pt-img2 animate2" src={ptImg2} alt=""/>
					<img className="pt-img3 animate3" src={ptImg3} alt=""/>
					<img className="pt-img4 animate4" src={ptImg4} alt=""/>
					<img className="pt-img5 animate-wave" src={ptImg5} alt=""/>
				</div>
				
			</>
		);
	}
}

export default aboutSection;